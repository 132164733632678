// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navb2{
    height: 100% !important;
}

.calendario-prueba {
    display: flex;
    width: 100%;
    height: 500px;
}`, "",{"version":3,"sources":["webpack://./src/terms.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,WAAW;IACX,aAAa;AACjB","sourcesContent":[".navb2{\n    height: 100% !important;\n}\n\n.calendario-prueba {\n    display: flex;\n    width: 100%;\n    height: 500px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
