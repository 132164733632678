// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../static/stars.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rating {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .rating-number {
    font-size: 1.2rem;
    margin-right: 0.5rem;
  }
  
  .stars {
    display: flex;
  }
  
  .star {
    width: 1rem;
    height: 1rem;
    margin-right: 0.2rem;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
  }
    
  .star.full {
    background-position: 0 0;
  }
  
  .star.half {
    background-position: -50% 0;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/Rating.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;EACxB;;EAEA;IACE,iBAAiB;IACjB,oBAAoB;EACtB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,oBAAoB;IACpB,yDAA4C;IAC5C,sBAAsB;EACxB;;EAEA;IACE,wBAAwB;EAC1B;;EAEA;IACE,2BAA2B;EAC7B","sourcesContent":[".rating {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n  }\n  \n  .rating-number {\n    font-size: 1.2rem;\n    margin-right: 0.5rem;\n  }\n  \n  .stars {\n    display: flex;\n  }\n  \n  .star {\n    width: 1rem;\n    height: 1rem;\n    margin-right: 0.2rem;\n    background-image: url('../static/stars.svg');\n    background-size: cover;\n  }\n    \n  .star.full {\n    background-position: 0 0;\n  }\n  \n  .star.half {\n    background-position: -50% 0;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
