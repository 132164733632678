// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .carousel-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 0 10px;
  }
  
  .carousel-images {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 600px;
    position: relative;
  }
  
  .carousel-image {
    opacity: 0.5;
    transition: transform 0.5s, opacity 0.5s;
  }
  
  .carousel-image.active {
    transform: scale(1.5);
    opacity: 1;
  }
  
  .carousel-image.adjacent {
    transform: scale(1);
    opacity: 0.7;
  }
  
  .carousel-image:not(.active):not(.adjacent) {
    display: none;
  }`, "",{"version":3,"sources":["webpack://./src/styles/CarouselBlog.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,wCAAwC;EAC1C;;EAEA;IACE,qBAAqB;IACrB,UAAU;EACZ;;EAEA;IACE,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,aAAa;EACf","sourcesContent":[".carousel-container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .carousel-button {\n    background: none;\n    border: none;\n    font-size: 24px;\n    cursor: pointer;\n    padding: 0 10px;\n  }\n  \n  .carousel-images {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 600px;\n    position: relative;\n  }\n  \n  .carousel-image {\n    opacity: 0.5;\n    transition: transform 0.5s, opacity 0.5s;\n  }\n  \n  .carousel-image.active {\n    transform: scale(1.5);\n    opacity: 1;\n  }\n  \n  .carousel-image.adjacent {\n    transform: scale(1);\n    opacity: 0.7;\n  }\n  \n  .carousel-image:not(.active):not(.adjacent) {\n    display: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
